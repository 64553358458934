import axios from "axios";

import { notification } from "antd";
import config from "../config/config";
import moment from "moment";

const helper = {
  validateSlug: async (slug, modelName) => {
    try {
      const { data: res } = await axios.post(
        `${config.api_url}general/validation`,
        {
          slug,
          modelName,
        }
      );

      if (res.status !== 200) {
        return false;
      }
      return true;
    } catch (error) {
      notification["error"]({
        message: "Internal Server Error",
      });
      console.log("error", error.response);
      //   return false;
    }
  },

  ISTDate: (date, format = "YYYY-MM-DD HH:mm:ss") => {
    return moment(date).utc().utcOffset("+05:30").format(format);
  },
};
export default helper;

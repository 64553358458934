import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Tooltip,
  Descriptions,
  Drawer,
  Button,
  Space,
} from "antd";
import { Table } from "ant-table-extensions";
import http from "../../helpers/http";
import helper from "../../helpers/helper";
import config from "../../config/config";
import moment from "moment";
function Index() {
  const [loading, setloading] = useState(false);

  const [datas, setDatas] = useState([]);
  const [data, setData] = useState({});
  const [drawerOpen, setdraweropen] = useState(false);

  const moduleNamePural = "Contact Form";
  const base_url = config.api_url + "admin/leads/contact"; //without trailing slash
  const image_url = config.api_url + ""; //with trailing slash

  const fetchDatas = async () => {
    setloading(true);
    const data = await http.get(base_url);

    if (data) {
      setDatas(data.data);
    }
    setloading(false);
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  const handleView = async (id) => {
    const { data } = await http.get(`${base_url}/${id}`);

    if (data) {
      setData(data);
      console.log(data);
      setdraweropen(true);
    }
  };

  const handleDrawerClose = () => {
    setData({});
    setdraweropen(false);
  };

  const fields = {
    name: "Name",
   
    email: "Email",
    phone: "phone",
    state: "state",
    city: "city",
    pincode: "pincode",
    investment: "investment",
    property: "property",
    

  

    createdAt: {
      header: "Created At",
      formatter: (_fieldValue, record) => {
        return helper.ISTDate(record.createdAt);
      },
    },
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name?.localeCompare(b.name),
    },
  
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email?.localeCompare(b.email),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      
    },

    {
      title: "Created At",
      render: (text, record) => {
        return helper.ISTDate(record.createdAt);
      },
      key: "createdAt",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              handleView(record._id);
            }}
          >
            View Detail
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Leads</Breadcrumb.Item>
        <Breadcrumb.Item>{moduleNamePural}</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col span={24}>
          <div className="site-card-border-less-wrapper">
            <Card title={moduleNamePural} bordered={false}>
              <Table
                rowKey={(record) => record._id}
                columns={columns}
                dataSource={datas}
                loading={loading}
                exportableProps={{
                  showColumnPicker: false,
                  fields,
                  fileName: moduleNamePural,
                }}
                searchable
              />
            </Card>

            <Drawer
              title="Lead Details"
              placement="right"
              onClose={handleDrawerClose}
              visible={drawerOpen}
              size="large"
              width={1000}
            >
              {data && (
                <Descriptions
                  bordered
                  column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                  size="small"
                >
                  <Descriptions.Item label="Name">
                    {data.name || "NA"}
                  </Descriptions.Item>
              
                  <Descriptions.Item label="Email">
                    {data.email || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Phone">
                    {data.phone || "NA"}
                  </Descriptions.Item>
                
                  <Descriptions.Item label="State">
                    {data.state || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="City">
                    {data.city || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Pincode">
                    {data.pincode || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Are you ready to invest 80 Lacs+ ?">
                    {data.investment || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Do you have any property on lease or ownership (1200 sq.ft.+) ?">
                    {data.property || "NA"}
                  </Descriptions.Item>
                

                  <Descriptions.Item label="Created At">
                    {helper.ISTDate(data.createdAt)}
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Drawer>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Index;

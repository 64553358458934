import React from "react";
import "./App.less";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Login from "./pages/Auth/Login";
import MainLayout from "./MainLayout";
import config from "./config/config";
import jwtDecode from "jwt-decode";
import Logout from "./components/Logout";

function App() {
  const PrivateRoute = ({ component: Component, ...rest }) => {
    let user;
    try {
      const token = localStorage.getItem(config.jwt_store_key);
      user = jwtDecode(token);
    } catch (error) {}

    return (
      // Show the component only when the user is logged in
      // Otherwise, redirect the user to /signin page
      <Route
        {...rest}
        render={(props) =>
          user ? <Component {...props} /> : <Redirect to="/login" />
        }
      />
    );
  };

  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <PrivateRoute path="/" component={MainLayout} />
      </Switch>
    </Router>
  );
}

export default App;

import React, { useState } from "react";
import "./App.less";
import { Layout, Menu, Modal, Drawer, Button } from "antd";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import {
  ExclamationCircleOutlined,
  MenuOutlined,
  
  LineChartOutlined
} from "@ant-design/icons";

import Contact from "./pages/leads/Contact";

import NotFound from "./pages/NotFound/Index";

import Logout from "./components/Logout";

function MainLayout(props) {
  const { Header, Content, Footer, Sider } = Layout;
  const { SubMenu } = Menu;
  const { confirm } = Modal;
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedKeys, setselectedKeys] = useState(0);
  const onClose = () => {
    setOpen(false);
  };
  const onCollapse = (collapsed) => {
    setCollapsed(!collapsed);
  };

  const showLogoutConfirm = (id) => {
    confirm({
      title: `Are you sure you want to logout?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        // return handleDelete(id);
        props.history.push("/logout");
      },
      onCancel() {},
    });
  };

  const MenuComponent = (
    <Menu theme="light" mode="inline">
      <SubMenu key="leads" icon={<LineChartOutlined />} title="Leads">
        <Menu.Item key="9">
          <Link to="/leads/contact">Contact</Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
  return (
    <Router>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          // theme="dark"
          // collapsible
          // collapsed={collapsed}
          // onCollapse={() => {
          //   onCollapse(collapsed);
          // }}
          theme="light"
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          trigger={null}
        >
          {/* <div className="logo">
            <span>Raymond</span>
            <img src={Logo} className="img-fluid" />
          </div> */}
          {MenuComponent}
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <Button
              className="menu"
              type="primary"
              icon={<MenuOutlined />}
              onClick={() => setOpen(true)}
            />
            <Menu theme="dark" mode="horizontal">
              <Menu.Item key="1">
                <Link
                  onClick={() => {
                    showLogoutConfirm();
                  }}
                >
                  Logout
                </Link>
              </Menu.Item>
            </Menu>
          </Header>
          <Content style={{ margin: "0 16px" }}>
            <Switch>
              <Route exact path="/" component={Contact} />

              <Route exact path="/leads/contact" component={Contact} />

              <Route exact path="/logout" component={Logout} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Design & Developed by Togglehead
          </Footer>
        </Layout>

        <Drawer title="Menu" placement="left" onClose={onClose} open={open}>
          {MenuComponent}
        </Drawer>
      </Layout>
    </Router>
  );
}

export default MainLayout;
